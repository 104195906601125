import React from 'react';
import { Box, Container, Flex, ListItem, UnorderedList } from '@chakra-ui/react';
import { ChannelConnectionQuality } from '@egzotech/exo-session/features/cable';
import { getMuscleName } from 'config/muscles';
import { __ } from 'helpers/i18n';
import { CalibrationFlow } from 'libs/exo-session-manager/core';
import { BodyPartMuscle, Exercise, ProgressStep } from 'types/exercise';

import { MainButton } from 'components/buttons/MainButton';
import { ErrorOutline } from 'components/icons';
import { TranslateText } from 'components/texts/TranslateText';

import { CalibrationFlowBackButton } from './CalibrationFlowBackButton';
import { CalibrationFlowNextButton } from './CalibrationFlowNextButton';

interface StepStatusProps {
  step: ProgressStep;
  currentMuscle: BodyPartMuscle;
  electrodesConnectionState: {
    allDisconnected: boolean;
    allConnected: boolean;
    allCorrect: boolean;
  };
  flow: CalibrationFlow;
  currentExercise: Exercise;
  currentElectrode: number;
  onActivatePort(): void;
  onBackClick(): void;
  onNextClick(): void;
}

export const ConnectElectrodesStepStatus = ({
  step: currentElectrodeStatus,
  currentMuscle,
  electrodesConnectionState,
  flow,
  currentExercise,
  currentElectrode,
  onActivatePort,
  onBackClick,
  onNextClick,
}: StepStatusProps) => {
  const currentState = getCurrentStatusName(currentElectrodeStatus);
  const exerciseType = currentExercise.type + '-' + (currentElectrode === 0 ? '1' : '2');

  return (
    <Container variant="electrodesInfo">
      <TranslateText
        variant="openSans36Bold"
        letterSpacing="0"
        text={`exercise.electrodesConnection.${currentState}.heading`}
        replace={{
          muscleName: __(`muscles.${getMuscleName(currentMuscle.muscleId)}`, null, 'muscles.unknown'),
        }}
        mb="10"
      />
      {currentState !== 'connected' && (
        <Box mt="auto" mb="14">
          <TranslateText
            variant="openSans24Bold"
            text={`exercise.electrodesConnection.${currentState}.description`}
            replace={{ channelIndex: currentMuscle.muscleId }}
            mb="7"
            letterSpacing="0"
          />

          <UnorderedList textAlign="start" mt="6">
            {currentState === 'waiting' && (
              <ListItem mb="2">
                <TranslateText
                  variant="openSans24"
                  letterSpacing="0"
                  text={`exercise.electrodesConnection.${currentState}.exerciseType.${exerciseType}`}
                />
              </ListItem>
            )}
            <ListItem mb="2">
              <TranslateText
                variant="openSans24"
                letterSpacing="0"
                text={`exercise.electrodesConnection.${currentState}.info.1`}
              />
            </ListItem>
            <ListItem mb="2">
              <TranslateText
                variant="openSans24"
                letterSpacing="0"
                text={`exercise.electrodesConnection.${currentState}.info.2`}
              />
            </ListItem>
            <ListItem mb="2">
              <TranslateText
                variant="openSans24"
                letterSpacing="0"
                text={`exercise.electrodesConnection.${currentState}.info.3`}
              />
            </ListItem>
            <ListItem mb="2">
              <TranslateText
                variant="openSans24"
                letterSpacing="0"
                text={`exercise.electrodesConnection.${currentState}.info.4`}
              />
            </ListItem>
          </UnorderedList>
        </Box>
      )}
      <Flex justifyContent="space-between" mt="auto">
        {electrodesConnectionState.allDisconnected ? (
          <CalibrationFlowBackButton flow={flow} />
        ) : (
          <MainButton
            variant={{ lg: 'smOutlinedPrimaryButton', '2xl': 'mdOutlinedPrimaryButton' }}
            text="common.back"
            onClick={onBackClick}
            data-testid="electrodes-info-back-button"
          />
        )}
        {electrodesConnectionState.allConnected ? (
          <CalibrationFlowNextButton
            isFormValidation
            validationPassed={electrodesConnectionState.allCorrect}
            flow={flow}
            onNext={onActivatePort}
          />
        ) : (
          <MainButton
            type="submit"
            isDisabled={currentElectrodeStatus.status === ChannelConnectionQuality.NONE}
            variant={
              currentElectrodeStatus.status === ChannelConnectionQuality.POOR
                ? {
                    lg: 'smWarningButton',
                    '2xl': 'mdWarningButton',
                  }
                : {
                    lg: 'smPrimaryButton',
                    '2xl': 'mdPrimaryButton',
                  }
            }
            text="common.next"
            onClick={onNextClick}
            rightIcon={currentElectrodeStatus.status === ChannelConnectionQuality.POOR ? <ErrorOutline /> : undefined}
            data-testid="electrodes-info-next-button"
          />
        )}
      </Flex>
    </Container>
  );
};

const getCurrentStatusName = (step: ProgressStep) => {
  switch (step.status) {
    case ChannelConnectionQuality.WELL:
      return 'connected';
    case ChannelConnectionQuality.NONE:
      return step.timedOut ? 'disconnected' : 'waiting';
    case ChannelConnectionQuality.POOR:
      return 'warning';
  }
};
