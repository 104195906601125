import { useEffect, useMemo, useState } from 'react';
import { ExoModuleIdentification } from '@egzotech/exo-session/features/module-identification';
import { logger } from 'helpers/logger';
import { useDevice } from 'libs/exo-session-manager/react';
import { useExpectedFirmwareVersionQuery } from 'queries/device';
import { ExoClinicBackendOpenApiPaths } from 'services/ExoClinicBackendOpenApi';

export type DeviceExpectedFirmwareModuleValue = {
  version: Omit<ExoModuleIdentification['version'], 'hardware'>;
  moduleId: string;
};
export type DeviceExpectedFirmwareModule = Record<string, DeviceExpectedFirmwareModuleValue>;

function parseVersionString(version: string): Omit<ExoModuleIdentification['version'], 'hardware'> {
  const values = version.split('.');

  return {
    major: +values[0],
    minor: +values[1],
    revision: +values[2],
  };
}

function parseBackendExpectedVersion(
  data: ExoClinicBackendOpenApiPaths['/device/update/firmware/valid-version']['get']['response'],
) {
  const result: DeviceExpectedFirmwareModule = {};

  for (const module in data) {
    result[module] = {
      version: parseVersionString(data[module]),
      moduleId: module,
    };
  }

  return result;
}

export function checkExpectedVersionCompatibility(
  current: ExoModuleIdentification,
  expected: DeviceExpectedFirmwareModule[string],
  allowHigherVersion: boolean,
) {
  if (!allowHigherVersion) {
    return (
      current.version.major === expected.version.major &&
      current.version.minor === expected.version.minor &&
      current.version.revision === expected.version.revision
    );
  }

  return (
    current.version.major === expected.version.major &&
    current.version.minor >= expected.version.minor &&
    current.version.revision >= expected.version.revision
  );
}

export function convertVersionToString(version: Omit<ExoModuleIdentification['version'], 'hardware'>) {
  return `${version.major}.${version.minor}.${version.revision}`;
}

// Modules needs to be checked later, because the page needs to wait for all identifications
let globalAllowModuleCheck: boolean | null = null;
const emptyArray = [] as DeviceExpectedFirmwareModuleValue[];

export function useFirmwareVersion() {
  const { firmwareModules, session, selectedDeviceId } = useDevice();
  const { data, isFetching } = useExpectedFirmwareVersionQuery(selectedDeviceId);
  const [allowModuleCheck, setAllowModuleCheck] = useState(globalAllowModuleCheck);
  const expected = useMemo(() => (data ? parseBackendExpectedVersion(data) : null), [data]);
  const firmwareModulesArray = useMemo(() => Object.values(firmwareModules), [firmwareModules]);
  const expectedArray = useMemo(() => Object.values(expected ?? {}), [expected]);
  const incompatibleModules = useMemo(
    () =>
      expectedArray.filter(
        v =>
          firmwareModules[v.moduleId] &&
          !checkExpectedVersionCompatibility(firmwareModules[v.moduleId]!.identification, v, false),
      ),
    [expectedArray, firmwareModules],
  );

  const missingModules = useMemo(
    () => expectedArray.filter(k => !firmwareModules[k.moduleId] || firmwareModules[k.moduleId]!.isMissing),
    [expectedArray, firmwareModules],
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    logger.info(
      'useFirmwareVersion',
      'Loaded expected firmware version: \n' +
        Object.entries(data)
          .map(([k, v]) => `  - ${k} - ${v}`)
          .join('\n'),
    );
  }, [data]);

  useEffect(() => {
    if (allowModuleCheck !== null || !session) {
      return;
    }

    setAllowModuleCheck(false);

    setTimeout(() => {
      logger.info('useFirmwareVersion', 'Allowing for module checking');
      globalAllowModuleCheck = true;
      setAllowModuleCheck(true);
    }, 3000);
  }, [allowModuleCheck, session]);

  if (!expected || isFetching || !allowModuleCheck) {
    return {
      current: firmwareModulesArray,
      expected: emptyArray,
      compatible: false,
      isModuleMissing: false,
      hasAnyIncompatibleModule: false,
      incompatibleModules: emptyArray,
      missingModules: emptyArray,
    };
  }

  return {
    current: firmwareModulesArray,
    expected: expectedArray,
    compatible: incompatibleModules.length === 0 && missingModules.length === 0,
    isModuleMissing: missingModules.length > 0,
    hasAnyIncompatibleModule: incompatibleModules.length > 0,
    incompatibleModules,
    missingModules,
  };
}
