import { GeneratedCPMExerciseDefinition } from '../../types/GeneratedExerciseDefinition';

export const getPredefinedSidraLegCPMEMGExercises = (): { [key: string]: GeneratedCPMExerciseDefinition } => ({
  cpm_emg_classic_knee: {
    type: 'cpm-emg',
    calibrationFlow: 'cpm-emg/knee',
    tags: ['emg'],
    cpm: {
      knee: {
        program: {
          phases: [
            {
              trigger: 'direction-change',
              repetitions: 10,
              maxSpeed: 20,
              speed: 10,
              coupling: {
                force: {
                  knee: {
                    sensitivity: 0,
                    deadband: 0,
                  },
                },
              },
            },
          ],
          maxBackwardForceLimit: 5,
        },
        parameters: {
          triggeringType: {
            default: 'uni-directional',
            unit: 'none',
            values: ['uni-directional', 'bi-directional'],
            blockAfterStart: true,
          },
          phases: [
            {
              movementType: {
                default: 'normal',
                unit: 'none',
                values: ['reverse', 'normal'],
                blockAfterStart: true,
              },
              triggeringMethod: {
                default: 'triggerAndRelease',
                unit: 'none',
                values: ['triggerAndRelease', 'triggerAndHold'],
                blockAfterStart: false,
              },
              repetitions: {
                default: 10,
                unit: 'number',
                values: [5, 10, 15, 20, 50, 100],
                blockAfterStart: false,
              },
              speed: {
                default: 10,
                unit: 'deg/s',
                values: [0.2, 1, 2, 5, 10, 15, 20],
                blockAfterStart: false,
              },
              pauseTimeInROMMin: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMax: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
            },
          ],
          maxBackwardForceLimit: {
            default: 5,
            unit: 'kg',
            values: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
              30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
              56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
              82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
            ],
            blockAfterStart: false,
          },
        },
      },
      ankle: {
        program: null,
      },
    },
  },
  cpm_emg_classic_ankle: {
    type: 'cpm-emg',
    calibrationFlow: 'cpm-emg/ankle',
    tags: ['emg'],
    cpm: {
      ankle: {
        program: {
          phases: [
            {
              trigger: 'direction-change',
              repetitions: 10,
              maxSpeed: 60,
              speed: 30,
              coupling: {
                force: {
                  knee: {
                    sensitivity: 0,
                    deadband: 0,
                  },
                },
              },
            },
          ],
          maxBackwardForceLimit: 5,
        },
        parameters: {
          triggeringType: {
            default: 'uni-directional',
            unit: 'none',
            values: ['uni-directional', 'bi-directional'],
            blockAfterStart: true,
          },
          phases: [
            {
              movementType: {
                default: 'normal',
                unit: 'none',
                values: ['reverse', 'normal'],
                blockAfterStart: true,
              },
              triggeringMethod: {
                default: 'triggerAndRelease',
                unit: 'none',
                values: ['triggerAndRelease', 'triggerAndHold'],
                blockAfterStart: false,
              },
              repetitions: {
                default: 10,
                unit: 'number',
                values: [5, 10, 15, 20, 50, 100],
                blockAfterStart: false,
              },
              speed: {
                default: 30,
                unit: 'deg/s',
                values: [0.2, 1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMin: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMax: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
            },
          ],
          maxBackwardForceLimit: {
            default: 5,
            unit: 'kg',
            values: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
              30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
              56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
              82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
            ],
            blockAfterStart: false,
          },
        },
      },
      knee: {
        program: null,
      },
    },
  },
  cpm_emg_classic_knee_synced: {
    type: 'cpm-emg',
    calibrationFlow: 'cpm-emg/knee-synced',
    tags: ['emg'],
    cpm: {
      ankle: {
        program: {
          phases: [
            {
              trigger: 'direction-change',
              repetitions: 10,
              maxSpeed: 60,
              speed: 30,
              coupling: {
                force: {
                  knee: {
                    sensitivity: 0,
                    deadband: 0,
                  },
                },
              },
            },
          ],
          maxBackwardForceLimit: 5,
        },
        parameters: {
          triggeringType: {
            default: 'uni-directional',
            unit: 'none',
            values: ['uni-directional', 'bi-directional'],
            blockAfterStart: true,
          },
          phases: [
            {
              movementType: {
                default: 'normal',
                unit: 'none',
                values: ['reverse', 'normal'],
                blockAfterStart: true,
              },
              triggeringMethod: {
                default: 'triggerAndRelease',
                unit: 'none',
                values: ['triggerAndRelease', 'triggerAndHold'],
                blockAfterStart: false,
              },
              repetitions: {
                default: 10,
                unit: 'number',
                values: [5, 10, 15, 20, 50, 100],
                blockAfterStart: false,
              },
              speed: {
                default: 30,
                unit: 'deg/s',
                values: [0.2, 1, 2, 5, 10, 15, 20, 25, 30, 40, 50, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMin: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMax: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
            },
          ],
          maxBackwardForceLimit: {
            default: 5,
            unit: 'kg',
            values: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
              30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
              56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
              82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
            ],
            blockAfterStart: false,
          },
        },
      },
      knee: {
        program: null,
        synchronized: 'ankle',
      },
    },
  },
  cpm_emg_classic_ankle_synced: {
    type: 'cpm-emg',
    calibrationFlow: 'cpm-emg/ankle-synced',
    tags: ['emg'],
    cpm: {
      knee: {
        program: {
          phases: [
            {
              trigger: 'direction-change',
              repetitions: 10,
              maxSpeed: 20,
              speed: 10,
              coupling: {
                force: {
                  knee: {
                    sensitivity: 0,
                    deadband: 0,
                  },
                },
              },
            },
          ],
          maxBackwardForceLimit: 5,
        },
        parameters: {
          triggeringType: {
            default: 'uni-directional',
            unit: 'none',
            values: ['uni-directional', 'bi-directional'],
            blockAfterStart: true,
          },
          phases: [
            {
              movementType: {
                default: 'normal',
                unit: 'none',
                values: ['reverse', 'normal'],
                blockAfterStart: true,
              },
              triggeringMethod: {
                default: 'triggerAndRelease',
                unit: 'none',
                values: ['triggerAndRelease', 'triggerAndHold'],
                blockAfterStart: false,
              },
              repetitions: {
                default: 10,
                unit: 'number',
                values: [5, 10, 15, 20, 50, 100],
                blockAfterStart: false,
              },
              speed: {
                default: 10,
                unit: 'deg/s',
                values: [0.2, 1, 2, 5, 10, 15, 20],
                blockAfterStart: false,
              },
              pauseTimeInROMMin: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
              pauseTimeInROMMax: {
                default: 0,
                unit: 's',
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 60],
                blockAfterStart: false,
              },
            },
          ],
          maxBackwardForceLimit: {
            default: 5,
            unit: 'kg',
            values: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
              30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
              56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
              82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
            ],
            blockAfterStart: false,
          },
        },
      },
      ankle: {
        program: null,
        synchronized: 'knee',
      },
    },
  },
});
